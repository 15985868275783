<template>
  <div
    class="side-menu"
    :class="{ 'side-menu--open': isMenuOpen }"
    data-scroll-lock-scrollable
  >
    <div class="side-menu__element" v-for="item in navData" :key="item.index">
      <div
        class="side-menu__group-title"
        :class="{ 'side-menu__group-title--active': item.isOpen }"
        @click="setActive(item)"
      >
        <span>{{ item.name }}</span>
      </div>
      <div class="side-menu__subgroup">
        <div
          class="side-menu__subgroup-item"
          v-for="article in item.articles"
          :key="article.index"
        >
          <div
            v-if="article.showInMenu"
          >
            <router-link
              v-if="article.redirect === false"
              :to="`/${article.urlPath}`"
              active-class="active"
              @click.native="selectMenuElement"
              >{{ article.name }}</router-link
            >

            <a
              v-if="article.redirect"
              :href="`${article.urlPath}`"
              target="_blank"
            >
              {{ article.name }}
              <!-- <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 1H2C1.44772 1 1 1.44772 1 2V8C1 8.55228 1.44772 9 2 9H8C8.55228 9 9 8.55228 9 8V7M4 6L9 1" stroke="black" stroke-linecap="round"/>
                <path d="M5.5 1H9V4.5" stroke="black" stroke-linecap="round"/>
              </svg> -->
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 9L8 2" stroke="black" stroke-linecap="round"/>
                <path d="M1.5 2H8V8.5" stroke="black" stroke-linecap="round"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

    <a
      v-if="menuButton.title !== ''"
      :href="`${menuButton.href}`"
      class="side-menu__special-link"
      target="_blank"
    >
      {{ menuButton.title }}
    </a>
  </div>
</template>

<script>
import StaticService from "../services/static";

export default {
  name: "Menu",
  data: () => ({
    isMobile: false,
    menuButton: {
      title: window.params.menuButton.title,
      href: window.params.menuButton.href,
    },
  }),
  computed: {
    navData() {
      return this.$store.getters.getNav;
    },
    isMenuOpen() {
      return this.$store.state.isMenuOpen;
    },
  },
  created() {
    this.isFooterShowImNemu = window.innerWidth < 768;
    this.isMobile = window.innerWidth < 1280;

    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 1280;
      this.isFooterShowImNemu = window.innerWidth < 768;
    });

    this.staticService = new StaticService();
  },
  watch: {
    $route() {
      this.closeAll();
      this.findCurrent();
    },
    navData() {
      this.findCurrent();
    },
    isMenuOpen(val) {
      if (!val) {
        this.closeAll();
        this.findCurrent();
      }
    },
  },
  methods: {
    selectMenuElement() {
      if (this.isMobile) {
        this.$store.commit("toggleMenu");
      }
    },
    findCurrent() {
      for (let i = 0; i < this.navData.length; i++) {
        const find = this.navData[i].articles.find((val) => {
          return val.urlPath === this.$route.params.title;
        });

        if (find) {
          this.navData[i]["isOpen"] = true;
          this.$forceUpdate();
          break;
        }
      }
    },
    closeAll() {
      this.navData.forEach((el) => {
        el["isOpen"] = false;
      });
    },
    setActive(item) {
      if (item.isOpen) {
        item["isOpen"] = false;
      } else {
        this.closeAll();

        item["isOpen"] = true;
      }

      this.$forceUpdate();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.side-menu {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.023em;
  color: #8e8e8e;
  //position: sticky;
  //top: 0;
  //height: 100vh;
  position: fixed;
  left: 0;
  padding: 0 32px;
  max-width: 300px;

  @media (max-width: 1279px) {
    box-sizing: border-box;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
    padding: 24px 72px;
    font-size: 16px;
    line-height: 28px;
    //overflow: auto;
    display: none;
    max-width: 100%;
    height: calc(100% - 80px);
    overflow-y: auto;
  }
}

.side-menu--open {
  display: flex;
}

.side-menu__element:not(:last-child) {
  margin-bottom: 24px;
}

.side-menu__group-title {
  // text-transform: capitalize;
  cursor: pointer;
}

.side-menu__group-title span {
  position: relative;
  transition: 300ms ease-out;

  &::after {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    border-bottom: 1px solid #8e8e8e;
    border-right: 1px solid #8e8e8e;
    top: 5px;
    right: -15px;
    transform: rotate(45deg);
  }
}

.side-menu__subgroup {
  display: none;
  padding: 24px 20px 16px;
}

//.side-menu__element:hover > .side-menu__group-title span,
.side-menu__group-title--active span {
  &::after {
    transform: rotate(-135deg);
    border-color: #000000;
    top: 7px;
  }
}

.side-menu__element:hover > .side-menu__group-title {
  color: #000000;
}

//.side-menu__group-title:hover + .side-menu__subgroup,
.side-menu__group-title--active + .side-menu__subgroup {
  display: block;
}

//.side-menu__element:hover .side-menu__subgroup {
//  display: block;
//}

.side-menu__subgroup-item svg {
  opacity: 0.25;
  margin-left: 5px;
  transition: 300ms ease-out;
}

.side-menu__subgroup-item a {
  text-decoration: none;
  color: inherit;
  transition: 300ms ease-out;
}

.side-menu__subgroup-item:not(:last-child) {
  margin-bottom: 12px;
}

.side-menu__subgroup-item:hover,
.side-menu__subgroup-item--active,
.side-menu__group-title--active,
.active {
  color: #000 !important;
}

.side-menu__subgroup-item:hover svg {
  opacity: 1;
}

.side-menu__special-link {
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.023em;
  color: #000000;
  padding: 8px 16px;
  text-decoration: none;
  max-width: max-content;
  margin: 0 0 24px;
  transition: 300ms ease-out;

  @media (max-width: 767px) {
    display: none;
  }

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.7);
    color: #000;
  }
}
</style>
