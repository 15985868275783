<template>
  <header class="header" :class="{'header--scrolled': isScrolled}" ref="header">
    <button type="button" class="header__menu" :class="{'header__menu--open': isMenuOpen}" @click="toggleMobileMenu">
      <div></div>
    </button>
    <div class="logo">
      <a href="https://dsgnsystem.ru/" target="_blank">
        <svg width="84" height="18" viewBox="0 0 84 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M0.00295471 0.881368H2.59762L14.7266 14.0708V0.881368H17.0497V17.09H14.4256L2.32308 3.87192V17.09H0L0.00295471 0.881368Z"
              fill="#121212"/>
          <path
              d="M37.7656 2.85977C39.5249 4.43485 40.4045 6.48111 40.4045 8.99856C40.4045 11.516 39.5249 13.5633 37.7656 15.1402C35.9945 16.7153 33.7767 17.5019 31.1122 17.5C28.4477 17.4981 26.2299 16.7115 24.4588 15.1402C22.6877 13.5652 21.8071 11.5179 21.8169 8.99856C21.8267 6.4792 22.7074 4.43294 24.4588 2.85977C26.2181 1.28469 28.4359 0.498092 31.1122 0.500003C33.7885 0.501915 36.0063 1.28851 37.7656 2.85977ZM24.1872 8.99856C24.2049 10.9942 24.8632 12.5674 26.162 13.7181C27.4608 14.8688 29.1158 15.4423 31.1269 15.4384C33.1165 15.4384 34.7655 14.8573 36.0742 13.6951C37.3828 12.5329 38.0372 10.9646 38.0372 8.98997C38.0372 7.0173 37.3828 5.44986 36.0742 4.28766C34.7655 3.12546 33.1165 2.54342 31.1269 2.54151C29.1138 2.54151 27.4588 3.11496 26.162 4.26186C24.8651 5.40877 24.202 6.98766 24.1725 8.99856H24.1872Z"
              fill="#121212"/>
          <path
              d="M43.1202 0.881368H45.7149L51.961 14.951H53.5608L59.8394 0.881368H62.4636L55.0397 17.09H50.4526L43.1202 0.881368Z"
              fill="#121212"/>
          <path
              d="M71.4548 0.881368H76.9156L84 17.09H81.5441L80.3634 14.4521H67.9008L66.7201 17.09H64.1844L71.4548 0.881368ZM72.9041 2.963L68.7981 12.3418H79.3597L75.3777 2.963H72.9041Z"
              fill="#121212"/>
        </svg>
      </a>
      <router-link :to="mainUrl">
        <span>
          / {{ name }}
        </span>
      </router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data: () => ({
    name: window.params.name,
    isScrolled: false,
    mainUrl: '/'
  }),
  computed: {
    isMenuOpen() {
      return this.$store.state.isMenuOpen;
    },
    navData() {
      return this.$store.getters.getNav;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, {passive: true});
  },
  watch: {
    navData() {
      this.getFirst();
    }
  },
  methods: {
    getFirst() {
      this.mainUrl = '/' + this.navData[0].articles[0].urlPath;
    },
    toggleMobileMenu() {
      this.$store.commit('toggleMenu');
    },
    handleScroll() {
      const html = document.documentElement;
      const body = document.body;
      const scrollTop = (html.scrollTop || (body && body.scrollTop) || 0) - html.clientTop;

      this.isScrolled = scrollTop > 0;
    }
  }
}
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  padding: 31px 32px;
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;

  @media (max-width: 1279px) {
    position: fixed;
    padding: 24px 16px;
  }

  @media (min-width: 1280px) {
    .logo {
      position: fixed;
      top: 31px;
    }
  }
}

  .logo {
    display: flex;
  }

.header--scrolled {
  @media (max-width: 1279px) {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.02), 0 8px 16px rgba(0, 0, 0, 0.05);
  }
}

.header__menu {
  @media (min-width: 1280px) {
    display: none;
  }

  @media (max-width: 1279px) {
    margin-right: 24px;
    width: 32px;
    height: 32px;
    background: transparent;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 17px;
      height: 2px;
      background-color: #000000;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #000000;
        top: -5px;
        left: 0;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #000000;
        bottom: -5px;
        left: 0;
      }
    }
  }
}

.header__menu--open div {
  background-color: transparent;

  &::before {
    top: 0;
    transform: rotate(45deg);
  }

  &::after {
    top: 0;
    transform: rotate(-45deg);
  }
}

.header a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.header span {
  font-size: 17px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.3);
  margin-left: 8px;
  font-family: 'SB-Sans-text', 'Arial', sans-serif;
}

</style>
