<template>
  <div>
    Select theme
  </div>
</template>

<script>

import StaticService from "../services/static";

export default {
  name: 'Home',
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s · NOVA',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'HOME DESCRIPTION',
      },
    ],
  },
  created() {
    this.staticService = new StaticService();
    this.getStructure();
  },
  methods: {
    getStructure() {
      this.staticService.getList()
          .then((res) => {
            this.$store.commit('setData', res.data);
            this.getFirst(res.data);
          })
    },
    getFirst(data) {
      const first = '/' + data[0].articles[0].urlPath;
      this.$router.push(first);
    }
  }
}
</script>
