import axios from 'axios';

export default class StaticService {
  // eslint-disable-next-line class-methods-use-this
  getList() {
    return axios.get(
      '/articles/structure.json',
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getArticle(src) {
    return axios.get(
      `/articles/${src}`,
    );
  }
}
