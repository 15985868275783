import Vue from 'vue';
import Vuex from 'vuex';

import scrollLock from 'scroll-lock';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navData: [],
    isMenuOpen: false
  },
  mutations: {
    setData(state, data) {
      state.navData = data;
    },
    toggleMenu(state) {
      state.isMenuOpen = !this.state.isMenuOpen;

      if (state.isMenuOpen) {
        scrollLock.disablePageScroll();
      } else {
        scrollLock.enablePageScroll();
      }
    }
  },
  getters: {
    getNav: state => {
      return state.navData;
    }
  },
  actions: {
  },
  modules: {
  }
})
