<template>
  <div id="app">
    <Header></Header>

    <main>
      <Menu></Menu>

      <div class="container">
        <router-view/>
      </div>
    </main>
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: "SB-Sans-text";
  src: url("./assets/fonts/sber-sans-text-regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "SB-Sans-display";
  src: url("./assets/fonts/sber-sans-display-semi.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "SB-Sans-display-light";
  src: url("./assets/fonts/sber-sans-display-light.woff2") format("woff2");
  font-display: swap;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  padding-top: 80px;

  @media (min-width: 1200px) {
    // display: grid;
    min-height: calc(100vh - 80px);
    // grid-template-rows: 1fr 80px;
  }
}

body {
  font-family: "SB-Sans-text", "Arial", sans-serif;
  margin: 0;
}

main {
  padding: 32px 40px 0 174px;

  @media (max-width: 1460px) {
    padding: 32px 40px 0 272px;
  }

  @media (max-width: 1279px) {
    padding: 40px 28px 0;
  }

  @media (max-width: 767px) {
    display: block;
    padding: 40px 24px 0;
  }
}

a {
  -webkit-tap-highlight-color: transparent;
}

.container {
  display: flex;
  justify-content: center;
}
</style>

<script>
import Header from "./components/Header";
import Menu from "./components/Menu";

export default {
  components: {Menu, Header},
  data: () => ({
    isMobile: false
  }),
  created() {
    this.isMobile = window.innerWidth < 768;

    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768;
    });
  }
}
</script>
